/* Serif font */
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'EB Garamond';
    src: local('EB Garamond'),
    url('./fonts/EB_Garamond/static/EBGaramond-Regular.ttf') format("truetype");
}
