
@media only screen and (min-width: 320px) {
}

@media only screen and (min-width: 768px) {
  /* Styles here */
  .contenedor {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: 1fr 1fr 1fr;  */
    grid-template-rows: auto auto auto;
    gap: 1em 1em;
    grid-template-areas:
      'newscontainer1 newscontainer1 newscontainer2'
      'newscontainer1 newscontainer1 newscontainer3'
      'newscontainer4 newscontainer5 newscontainer6';
  }
  .newscontainer1 {
    grid-area: newscontainer1;
  }
  .newscontainer2 {
    grid-area: newscontainer2;
  }
  .newscontainer3 {
    grid-area: newscontainer3;
  }
  .newscontainer4 {
    grid-area: newscontainer4;
  }
  .newscontainer5 {
    grid-area: newscontainer5;
  }
  .newscontainer6 {
    grid-area: newscontainer6;
  }
}

@media only screen and (min-width: 1200px) {

    .contenedor {
      width: 70%;
      margin: 0 auto;
    }
}

@media only screen and (min-width: 1600px) {
  /* Styles here */
}
